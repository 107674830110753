import { getDB, setDB } from '!/db'
import { createEffect, createRoot, createSignal } from 'solid-js'

export {}

const ALLOWD_THEMES = ['light', 'dark'] as const
type Theme = (typeof ALLOWD_THEMES)[number]

async function default_theme(): Promise<Theme> {
    let theme: Theme

    try {
        theme = (await getDB('USER_THEME')) as Theme

        if (ALLOWD_THEMES.includes(theme)) {
            return theme
        }
    } catch {}

    if (matchMedia && matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark'
    } else {
        return 'light'
    }
}

export const [theme, setTheme] = createSignal<Theme>('light')
;(async () => {
    const initialTheme = await default_theme()
    setTheme(initialTheme)
})()

createRoot(() => {
    createEffect(() => {
        setDB('USER_THEME', theme())
        document.documentElement.setAttribute('data-theme', theme())
    })
})
