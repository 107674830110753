import { getDB, setDB } from '!/db'
import {
    AgentModel,
    SelfModel,
    SessionModel,
    UserInfo,
    UserModel,
} from '!/models'
import { httpx } from '!/shared'
import { createEffect, createRoot, on } from 'solid-js'
import { createStore } from 'solid-js/store'
import { USER } from './dbLabels'
import { MOCK_USER, MOCK_USER_INFO } from './user'

const EMPTY_AGENT: AgentModel = {
    gene: '',
    user: '',
    flag: 0,
    admin_perms: '0',
}

const sampleSelfModel: SelfModel = {
    token: 'sample_token_123',
    user: MOCK_USER_INFO,
    session: {
        ip: '192.168.1.1',
        info: {
            browser: 3,
            os: 2,
            device: 2,
            os_version: 11,
            browser_version: 134,
            client: 2,
            client_version: 1,
        },
        timestamp: 1722328515896,
        token: 'session_token_124',
    },
    agent: {
        admin_perms: '1',
        gene: '1',
        user: '1',
        flag: 1,
    },
}
const EMPTY_SELF: SelfModel = {
    token: '',
    user: MOCK_USER_INFO,
    session: {
        ip: '',
        info: {
            browser: 0,
            os: 0,
            device: 0,
            os_version: 0,
            browser_version: 0,
            client: 0,
            client_version: 0,
        },
        timestamp: 0,
        token: '',
    },
    agent: {
        admin_perms: '0',
        gene: '0',
        user: '0',
        flag: 0,
    },
}

async function get_default(): Promise<SelfModel> {
    try {
        const result: any = await getDB(USER)

        return {
            ...result,
        }
    } catch {}

    return {
        ...EMPTY_SELF,
    }
}

const [self, setself] = createStore<SelfModel>(await get_default())

createRoot(() => {
    createEffect(() => {
        if (!self.token) return

        get_default().then(result => setself(result))
    })
})

export { self, setself, sampleSelfModel, EMPTY_AGENT }
