import {
    CategoryType,
    EateryModel,
    EaterySearchModel,
    ThemeType,
} from '!/models'
import { createStore } from 'solid-js/store'

type searchModel = {
    show: boolean
    category: CategoryType
    theme: ThemeType
    search: string

    loading: boolean
    result: EateryModel[]
}
const [search, setSearch] = createStore<searchModel>({
    show: false,
    category: 'همه',
    theme: 'هیچ',

    search: '',

    loading: true,
    result: [],
})

export { type searchModel, search, setSearch }
