import { EateryModel } from '!/models'
import { createStore } from 'solid-js/store'

type EateryMapModel = {
    show: boolean
    fullscreen: boolean
    loading: boolean
    eatery: EateryModel | null
}

const [eateryMap, setEateryMap] = createStore<EateryMapModel>({
    show: false,
    fullscreen: false,
    loading: true,
    eatery: null,
})

export { type EateryMapModel, eateryMap, setEateryMap }
