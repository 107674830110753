import { getDB, setDB } from '!/db'
import { SavedEateriesModel } from '!/models'
import { createEffect, createRoot } from 'solid-js'
import { createStore } from 'solid-js/store'
import { USER_SAVE } from './dbLabels'

const [saved, setSaved] = createStore<SavedEateriesModel>([])

async function get_saved(): Promise<SavedEateriesModel> {
    try {
        const result = await getDB(USER_SAVE)

        return result ? [...result] : []
    } catch (error) {
        console.log(error)

        return []
    }
}

;(async function initializeSaved() {
    const result = await get_saved()
    setSaved(result)
})()

createRoot(() => {
    createEffect(() => {
        setDB(USER_SAVE, [...saved])
    })
})

export { saved, setSaved }
