import './preload'

import { Alert } from 'comps'
import Navbar from 'layout/navbar'
import { lazy, onMount, Show } from 'solid-js'
import { render } from 'solid-js/web'
import { activeTab, self } from './store'

const Home = lazy(() => import('pages/home'))
const Map = lazy(() => import('pages/map'))
const Account = lazy(() => import('pages/account'))
const Login = lazy(() => import('pages/login'))
const AddHome = lazy(() => import('comps/addHome'))

import './style/base.scss'
import './style/config.scss'
import './style/pcmode.scss'
import './style/theme.scss'

const APP_ID = 'gooje-offical-pwa'

export type MapChilrenType = {
    active: boolean
}

export const App = () => {
    onMount(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/public/sw.js')
        }

        document.addEventListener(
            'contextmenu',
            function (e) {
                e.preventDefault()
            },
            false
        )
    })

    const isSafari = navigator.userAgent.includes('Safari')

    return (
        <>
            {isSafari && // @ts-ignore
                !window.navigator.standalone && <AddHome />}

            <Show when={self.token} fallback={<Login />}>
                <Map active={activeTab() === 'map'} />
                <Account active={activeTab() === 'account'} />

                <Navbar />
            </Show>
            <Alert />
        </>
    )
}

render(() => <App />, document.getElementById('root'))
