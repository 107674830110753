import { UserInfo, UserModel } from '!/models'

export const MOCK_USER: UserModel = {
    gene: 'abc',
    flag: 0,
    review: 'sample_review_gene',
    photo: null,
    reviews: [1, 2, 3],
    phone: '09120974956',
    cc: 98,
    name: 'Sadra Taghavi',
    sessions: [
        {
            ip: '192.168.1.1',
            info: {
                browser: 2,
                os: 1,
                device: 1,
                os_version: 10,
                browser_version: 124,
                client: 2,
                client_version: 1,
            },
            timestamp: 1722328515896,
            token: 'session_token_123',
        },
    ],
}
export const MOCK_USER_INFO: UserInfo = {
    gene: 'abc',
    review: 'sample_review_gene',
    photo: {
        checksum: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
        width: 0,
        height: 0,
        size: 0,
        ext: '',
        duration: 0,
        alive: false,
        edited: false,
        private: false,
        url: '',
    },
    reviews: [1, 2, 3],
    phone: '09120974956',
    cc: 98,
    name: 'Sadra Taghavi',
    sessions: [
        {
            ip: '192.168.1.1',
            info: {
                browser: 2,
                os: 1,
                device: 1,
                os_version: 10,
                browser_version: 124,
                client: 2,
                client_version: 1,
            },
            timestamp: 1722328515896,
            token: 'session_token_123',
        },
    ],
}
